import React from "react";
import BaseLayouts from "./layouts/BaseLayouts";
function App() {

  return (
    <React.Fragment>
      <BaseLayouts></BaseLayouts>
    </React.Fragment>
  );
}

export default App;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone';
import { PiExport } from 'react-icons/pi'; 
import { FaFileLines } from "react-icons/fa6";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import axios from 'axios';
import { cityCore, Configuration, countryCore, detailAdd, detailSpecifyAdd, getDetails, getLanguages, getmcc, stateCore } from '../Api/Url';
import Select, { components } from 'react-select';
import { redirect, useLocation, useNavigate } from 'react-router-dom';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}


CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useFileDropzone = (onDrop, onDropRejected) => {
    return useDropzone({
        accept: { 'application/pdf': [] },
        onDrop,
        onDropRejected,
    });
};

const FileList = ({ files, removeFile, setter, base64Setter, base64Files }) => (
    <ul className='file-list'>
        {files.map((file, index) => (
            <li key={index} className='file-item'>
                <div className='file-details'>
                    <FaFileLines className='file-icon' />
                    <div className='file-info'>
                        <p className='file-name'>{file.name}</p>
                        <p className='file-size'>{(file.size / 1024).toFixed(2)} KB</p>
                    </div>
                </div>
                <button className='remove-btn' onClick={() => removeFile(file.name, setter, base64Setter, files, base64Files)}>x</button>
            </li>
        ))}
    </ul>
);

export default function UserAppTab() {

    const [value, setValue] = useState(0);
    const [files, setFiles] = useState([]);
    const [workFiles, setWorkFiles] = useState([]);
    const [letterFiles, setLetterFiles] = useState([]);
    const [error, setError] = useState('');
    const [base64FilesAcademic, setBase64FilesAcademic] = useState([]);
    const [base64FilesWork, setBase64FilesWork] = useState([]); 
    const [base64FilesLetter, setBase64FilesLetter] = useState('');
    
    const citySelect = [
        { value: 'Select City', label: 'Select City' }
    ];
    const religionSelect = [
        { value: 'Select Religion', label: 'Select Religion' }
    ];
    const booksSelect = [
        { value: 'Select Books', label: 'Select Books' }
    ];
    const denominationSelect = [
        { value: 'Select Denomination', label: 'Select Denomination' }
    ];

    const [pdfs, setPdfs] = useState([]);
    const [mainLanguage, setMainLanguage] = useState('');
    const [writingLanguage, setWritingLanguage] = useState('');
    const [email, setEmail] = useState('');
    const [firstAddress, setFirstAddress] = useState('');
    const [secondAddress, setSecondAddress] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [detailErr, setDetailErr] = useState({});
    const [detailSave, setDetailSave] = useState('');
    const [error422, setError422] = useState('');
    const [mobileChange, setMobileChange] = useState('');
    const [isValidNumber, setIsValidNumber] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState('');
    const [mccId, setMccId] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [dialCode, setDialCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [countryOptions, setCountryOptions] = useState([]);
    const [ipCountryCode, setIpCountryCode] = useState('in')  ;
    const [mainLanguageOptions, setMainLanguageOptions] = useState([]);
    const [countryListOption, setCountryListOption] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [stateListOption, setStateListOption] = useState([]);
    const [selectedState, setSelectedState] = useState([]); 
    const [cityListOption, setCityListOption] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    const [religion, setReligion] = useState('');
    const [books, setBooks] = useState('');
    const [denomination, setDenomination] = useState('');
    const [jobErr, setJobErr] = useState({})
    const [jobSave, setJobSave] = useState('');
    const [allowUserApplication, setAllowUserApplication] = useState('');
    const [urlLocation, setUrlLocation] = useState('');

    const config =Configuration();
    const navigate = useNavigate();
    const userNav = useNavigate();
    const location = useLocation();

        useEffect(() => {
            axios.get(getDetails(), config)
            .then(res => {
                const responseData = res.data.data;
                console.log(responseData);
            
                const queryParams = new URLSearchParams(location.search);
                const authUserId = queryParams.get('X_AuthUser');
                const jobId = queryParams.get('job_id');
                console.log(authUserId);
                console.log(jobId);
                
                // Use the extracted jobId and authUser in the next part
                // if (responseData.get_user_job_specify_detail !== null || responseData.user_detail !== null) {
                //     console.log('jobid--------', jobId);
                //     if (jobId) {
                //         console.log(authUserId);
                //         // const queryString = `job_id=${jobId}&X_AuthUser=${authUser}`;
                //         const queryString = authUserId ? `/u/${authUserId}/jobdetails?job_id=${jobId}` : `/jobdetails?job_id=${jobId}`;
                //         // const queryString = `u/${authUserId}&job_id=${jobId}`;
                //         console.log(queryString);
                //         userNav(queryString);
                //     }
                // }
                // console.log('noJobId');
            })
            .catch(err => console.log(err));
            console.log('outside');
        }, []);
        
        useEffect(() => {
            axios.get(getLanguages(), config)
                .then(res => {
                    const options = res.data.data.map(language => ({
                        value: language.code,
                        label: language.name,
                        id: language.id 
                    }));
                    setMainLanguageOptions(options);
                })
                .catch(err => console.log(err));
        }, []);

        useEffect(() => {
            axios.get(countryCore(), config)
                .then(res => {
                    const options = res.data.data.map(country => ({
                        value: country.id,
                        label: country.name,
                    }));
                    setCountryListOption(options);
                })
                .catch(err => console.log(err));
        }, []);

        useEffect(() => {
            selectedValueConvert()
        }, [writingLanguage,mainLanguage]);
    
        const handleCountryId = (selectedOption) => {
            setSelectedCountry(selectedOption); 
        };
    
        useEffect(() => {
            if (selectedCountry) { 
                axios.get(`${stateCore()}?country_id=${selectedCountry.value}`, config)
                    .then(res => {
                        const options = res.data.data.map(state => ({
                            value: state.id,
                            label: state.name
                        }));
                        setStateListOption(options);
                    })
                    .catch(err => console.log(err));
            }
        }, [selectedCountry]); 

        const handleStateId = (selectState) => {
            setSelectedState(selectState);
        };
        
        useEffect(() => {
            if (selectedState && selectedState.value) {  // Added check for selectedState.value
                axios.get(`${cityCore()}?state_id=${selectedState.value}`, config)
                    .then(res => {
                        const options = res.data.data.map(city => ({
                            value: city.id,
                            label: city.name
                        }));
                        setCityListOption(options);
                    })
                    .catch(err => console.log(err));
            }
        }, [selectedState]);        
        
        const handleCityId=(selectCity)=>{
            setSelectedCity(selectCity)
        }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDrop = (setter, base64Setter) => (acceptedFiles) => {
        setter(prevFiles => [...prevFiles, ...acceptedFiles]);
        setError('');

        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                base64Setter(prevBase64 => [...prevBase64, reader.result]);
            };
        });
    };

    const handleDropRejected = (setter) => (fileRejections) => {
        const rejectedFiles = fileRejections.map(file => file.file.name).join(', ');
        setError(`The following files are not allowed: ${rejectedFiles}`);
    };

    const { getRootProps: getRootPropsAcad, getInputProps: getInputPropsAcad } = useFileDropzone(
        handleDrop(setFiles, setBase64FilesAcademic),
        handleDropRejected(setFiles)
    );

    const { getRootProps: getRootPropsWrk, getInputProps: getInputPropsWrk } = useFileDropzone(
        handleDrop(setWorkFiles, setBase64FilesWork),
        handleDropRejected(setWorkFiles)
    );

    const handleDropString = (acceptedFiles) => {
        setLetterFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
        setError('');

        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setBase64FilesLetter(prevBase64 => prevBase64 + reader.result.split(',')[1]); // Remove the data URL prefix
            };
        });
    };

    const { getRootProps: getRootPropsLetter, getInputProps: getInputPropsLetter } = useDropzone({
        accept: { 'application/pdf': [] },
        onDrop: handleDropString,
        onDropRejected: handleDropRejected,
    });

    const validation=()=>{
        let detailErr = {};
        let isValid = true;
        
        if(mainLanguage == ''){
            detailErr.mainLanguage = 'Fill Main Language input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(writingLanguage == ''){
            detailErr.writingLanguage = 'Fill Writing Language input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(mobileChange == ''){
            detailErr.mobileChange = 'Fill Mobile Number';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(email == ''){
            detailErr.email = 'Fill Email input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(firstAddress == ''){
            detailErr.firstAddress = 'Fill Address Line 1 input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(secondAddress == ''){
            detailErr.secondAddress = 'Fill Address Line 2 input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(selectedCountry == ''){
            detailErr.selectedCountry = 'Fill Country input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(selectedState == ''){
            detailErr.selectedState = 'Fill State / Region input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(selectedCity == ''){
            detailErr.selectedCity = 'Fill City / Town input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(postalCode == ''){
            detailErr.postalCode = 'Fill Zip/Postal Code input';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(base64FilesAcademic == ''){
            detailErr.base64FilesAcademic = 'Select Academic History';
            setDetailSave('Please fill all input');
            isValid = false;
        }
        if(base64FilesWork == ''){
            detailErr.base64FilesWork = 'Select Work History';
            setDetailSave('Please fill all input');
            isValid = false;
        }

        setDetailErr(detailErr);
        return isValid
    }

    const jobValidation = () => {
        let jobErr = {};
        let isValid = true;
    
        if (!base64FilesLetter || base64FilesLetter.length === 0) {
            jobErr.base64FilesLetter = 'Fill this input';
            setJobSave('Select Add Cover Letter');
            isValid = false;
        } else {
            setJobSave(''); 
        }
    
        setJobErr(jobErr);
        return isValid;
    };

    function  selectedValueConvert(){
            let selectedMainLanguages = [];
            Array.isArray(writingLanguage)&&writingLanguage.map((selected)=>{
            let sampleObj={
               "id": selected.id,
              "is_main": "0",
            "is_written": "0"}
            if(mainLanguage) {
            if(selected.id === mainLanguage.id){
            sampleObj.is_main="1";
            sampleObj.is_written="1";
            }else{
            sampleObj.is_main="0";
            sampleObj.is_written="1";
            }
            }        
            selectedMainLanguages.push(sampleObj)
            })
            // console.log(selectedMainLanguages)
            return selectedMainLanguages
          }
          let result = selectedValueConvert();

    const detailAddData = {
        "languages": result,
        "mcc_id": parseInt(mccId),
        "phone": parseInt(mobileChange),
        "email": email,
        "address_line_1": firstAddress,
        "address_line_2": secondAddress,
        "city_id": selectedCity.value,
        "state_id": selectedState.value,
        "country_id": selectedCountry.value,
        "pincode": postalCode,
        "acadameic_attachments": base64FilesAcademic,
        "experienced_attachments": base64FilesWork
    };
    
    const userDetail = (e) => {
        e.preventDefault();
    
        axios.post(detailAdd(), detailAddData, config)
            .then(res => console.log(res))
            .catch(err => console.log(err));
    
        if (validation()) {
            setValue(1);
        }
    };

    const jobSpecifiesData = {
        "religion_id": 1,
        "denomination_id": 2,
        "books": [
            {
                "id": 1
            },
            {
                "id": 2
            }
        ],
         "cover_letter_attachment":base64FilesLetter
    }   

    const nav = useNavigate();

    const [loginId, setLoginId] = useState('');

    console.log(window.globalValue);
    
    useEffect(() => {
      const globalValue = window.globalValue;
      setLoginId(globalValue);
      console.log(globalValue, '--------');
    }, [window.globalValue]);
    console.log(loginId, '********');

    const jobSpecifies = (e) => {
        e.preventDefault();
        if (jobValidation()) {
            axios.post(detailSpecifyAdd(), jobSpecifiesData, config)
                .then(res => {
                    const queryParams = new URLSearchParams(location.search);
                    const jobId = queryParams.get('job_id');

                    // console.log(res);
                    if(loginId){
                        nav(`/u/${loginId}/jobdetails?job_id=${jobId}`);
                    }
                    else{
                        nav(`/jobdetails?job_id=${jobId}`);
                    }
                })
                .catch(err => console.log(err));
        } else {
            console.log('Validation failed');
        }
    };
    

    const removeFile = (fileName, setter, base64Setter, files, base64Files) => {
        const newFiles = files.filter(file => file.name !== fileName);
        const newBase64Files = base64Files.filter((_, index) => files[index].name !== fileName);
        setter(newFiles);
        base64Setter(newBase64Files);
    };
    const removeFileWrk = (fileName) => setWorkFiles(workFiles.filter(file => file.name !== fileName));
    const removeFileLetter = (fileName) => setLetterFiles(letterFiles.filter(file => file.name !== fileName));

    const updateExampleNumber = (iso2) => {
        if (window.intlTelInputUtils) {
          try {
            const exampleNumber = window.intlTelInputUtils.getExampleNumber(
              iso2,
              true,
              window.intlTelInputUtils.numberFormat.E164
            );
            const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
            const maxLength = cleanedNumber.length;
            return maxLength;
          } catch (error) {
            console.error('Error fetching example number for country code:', iso2, error);
            return 15; // Fallback to a default length
          }
        } else {
          console.error('intlTelInputUtils is not loaded');
          return 15; // Fallback to a default length
        }
      };
    
      const handlePhoneNumberChange = (value) => {
        setError422('');
        if (value.startsWith('0')) {
          value = value.slice(1);
        }
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
        if (value.length > maxLength) {
          value = value.slice(0, maxLength);
        }
        setMobileChange(value);
      };

      const mobileInputRef = useRef(null);

      const handlePaste = (e) => {
        setError422('');
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    
        // Define the dial code without the '+'
        const dialCodeWithoutPlus = dialCode.replace('+', '');
    
        // Remove dial code if the pasted text starts with '+'
        if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
            numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
        }
    
        // Remove leading '0' if present
        if (numericPastedText.startsWith('0')) {
            numericPastedText = numericPastedText.slice(1);
        }
    
        // Calculate the max length and truncate the number if necessary
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
        const truncatedPastedText = numericPastedText.slice(0, maxLength);
    
        setMobileChange(truncatedPastedText);
    
        e.preventDefault();
    };
    
    const handleCountryChange = (selectedOption) => {
      const selectedCountryData = countryOptions.find(
          (country) => country.value === selectedOption.value
      );
    
      if (selectedCountryData) {
          setMccId(selectedCountryData.mcc_id);
          setDialCode(`+${selectedCountryData.dial_code}`);
          setCountryCode(selectedCountryData.country_code);
    
          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
              mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
    
          // Truncate the mobile number to the new max length
          const truncatedMobileChange = mobileChange.slice(0, maxLength);
          setMobileChange(truncatedMobileChange);
      }
    };
      useEffect(()=>{
        if(ipCountryCode){
          fetchCountryCode()
        }
      },[ipCountryCode])
    
      const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* {data.label} */}
        </div>
      );

      const fetchCountryCode = async () => {
        setIsLoading(true); 
        try {
          const response = await axios.get(getmcc(), config);
          const data = response.data;
    
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
    
            setCountryOptions(codes);
    
            const selectedCountryData = codes.find(
              (country) => country.country_code === 'UG'
            );
    
            if (selectedCountryData) {
              setMccId(selectedCountryData.mcc_id);
              setDialCode(`+${selectedCountryData.dial_code}`);
              setCountryCode(selectedCountryData.country_code);
    
              const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false); // Set loading to false after the API call completes
        }
      };
    
      const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
      };

      let cancelBtn = useNavigate();
      

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Professional Details" {...a11yProps(0)} style={{ textTransform: 'capitalize', fontSize: '1.2rem' }} />
                    <Tab label="Job Specifies" {...a11yProps(1)} style={{ textTransform: 'capitalize', fontSize: '1.2rem' }} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className="checkout-form">
                    <form onSubmit={userDetail}>
                        <h3 className="title fz18 font-bold mb10">Languages</h3>
                        <div className="form-group row">
                            <div className="col-lg-6 col-md-12">
                                <label>Main Language:</label>
                                <Select
                                    options={mainLanguageOptions}
                                    value={mainLanguage}
                                    onChange={(selectedOption) => {
                                        setMainLanguage(selectedOption);
                                    }}
                                    placeholder="Select Main Language"
                                />
                                {/* <Dropdown
                                    options={mainLanguageOptions}
                                    value={mainLanguage}
                                    placeholder="Select Main Language"
                                    onChange={(e)=>{setMainLanguage(e.target)}}
                                /> */}
                                <span style={{color:'red'}}>{detailErr.mainLanguage}</span>
                            </div>
                            {/* <div className="col-lg-6 col-md-12">
                                <label>Writing Language:</label>
                                <Dropdown
                                    options={mainLanguageOptions}
                                    value={writingLanguage}
                                    placeholder="Select Writing Language"
                                    onChange={(e)=>{setWritingLanguage(e.target)}}
                                />
                                <span style={{color:'red'}}>{detailErr.writingLanguage}</span>
                            </div> */}
                            <div className="col-lg-6 col-md-12">
                            <label>Writing Language:</label>
                            <Select
                                options={mainLanguageOptions}
                                isMulti
                                value={writingLanguage}
                                placeholder="Select Writing Language"
                                onChange={(selectedOptions) => {
                                    setWritingLanguage(selectedOptions);

                                    const selectedLanguageIds = selectedOptions.map(option => option.id);
                                    // console.log("Selected Language IDs:", selectedLanguageIds);
                                }}
                            />
                            <span style={{color: 'red'}}>{detailErr.writingLanguage}</span>
                        </div>
                        </div>
                        <div className="form-group row mt20">
                            <div className="col-lg-6 col-md-12">
                                <label>Phone Number</label>
                                <div className='register-phone' style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ position: 'absolute', left: 0}} className='countrySelect'>
                                    <Select
                                        options={countryOptions}
                                        onChange={handleCountryChange}
                                        value={countryOptions.find(option => option.value === countryCode)}
                                        components={{ SingleValue: customSingleValue, Option: customOption }}
                                        placeholder=""
                                        isLoading={isLoading} 
                                        styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: isLoading ? '70px' : '80px',
                                            height: '100%',
                                            border: 'none',
                                            boxShadow: 'none',
                                            background: 'transparent',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }),
                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: '0 8px',
                                        }),
                                        indicatorSeparator: () => ({
                                            display: 'none',
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }),
                                        valueContainer: (provided) => ({
                                            ...provided,
                                            padding: '0',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }),
                                        input: (provided) => ({
                                            ...provided,
                                            margin: '0',
                                            padding: '0',
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            margin: '0',
                                            padding: '0',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            minWidth: '300px',
                                        }),
                                        }}
                                    />
                                    </div>
                                    <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
                                    <input
                                    type="text"
                                    className={`form-control docform ${mobileChange ? 'docform-active' : ''}`}
                                    id="mobile"
                                    ref={mobileInputRef}
                                    style={{ paddingLeft: '100px', width: '100%' }}
                                    placeholder="Mobile Number"
                                    value={mobileChange}
                                    name="mobile"
                                    onPaste={(e) => handlePaste(e)}
                                    //   onKeyPress={handleKeyPress}
                                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                                    />
                                </div>
                                <input
                                    type="hidden"
                                    placeholder="Mobile Number"
                                    value={mccId}
                                    onChange={(e) => setMccId(e.target.value)}
                                />
                                <span style={{color: 'red'}}>{detailErr.mobileChange}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label>Email</label>
                                <input className='register-form' placeholder='Enter Email' type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                                <span style={{color:'red'}}>{detailErr.email}</span>
                            </div>
                        </div>
                        <div className="form-group row mt20">
                            <h3 className="title fz18 font-bold mb10">Addresses</h3>
                            <div className="col-lg-6 col-md-12">
                                <label>Address Line 1:</label>
                                <input className='register-form' placeholder='Enter Address Line 1' type="text" value={firstAddress} onChange={(e)=>{setFirstAddress(e.target.value)}}/>
                                <span style={{color:'red'}}>{detailErr.firstAddress}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label>Address Line 2:</label>
                                <input className='register-form' placeholder='Enter Address Line 2' type="text" value={secondAddress} onChange={(e)=>{setSecondAddress(e.target.value)}}/>
                                <span style={{color:'red'}}>{detailErr.secondAddress}</span>
                            </div>
                        </div>
                        <div className="form-group row mt20">
                            <div className="col-lg-6 col-md-12">
                                <label>Country</label>
                                <Select
                                    options={countryListOption}
                                    value={selectedCountry}
                                    onChange={handleCountryId}
                                    placeholder="Select Country"
                                />
                                <span style={{color:'red'}}>{detailErr.selectedCountry}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label>State / Region</label>
                                <Select
                                    options={stateListOption}
                                    value={selectedState}
                                    onChange={handleStateId}
                                    placeholder="Select State"
                                />
                                <span style={{color:'red'}}>{detailErr.selectedState}</span>
                            </div>
                        </div>
                        <div className="form-group row mt20">
                            <div className="col-lg-6 col-md-12">
                                <label>City / Town</label>
                                <Select
                                    options={cityListOption}
                                    value={selectedCity}
                                    onChange={handleCityId}
                                    placeholder="Select City"
                                />
                                <span style={{color:'red'}}>{detailErr.selectedCity}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <label>Zip/Postal Code</label>
                                <input className='register-form' type="text" placeholder="Enter Postal code" value={postalCode} onChange={(e)=>{setPostalCode(e.target.value)}}/>
                                <span style={{color:'red'}}>{detailErr.postalCode}</span>
                            </div>
                        </div>
                        <div className="form-group row mt20 mb40">
                            <div className="col-lg-6 col-md-12">
                                <h3 className="title fz18 font-bold mb10">Academic History</h3>
                                <div className='FileCont' {...getRootPropsAcad()}>
                                    <input {...getInputPropsAcad()} type="file" multiple />
                                    <PiExport className='FilePdfIcon' />
                                    <p className='FilePdfIconPBold'>Click or drag file to this area to upload</p>
                                    <p className='FilePdfIconP'>Upload document as a file in pdf</p>
                                </div>
                                <span style={{color:'red'}}>{detailErr.base64FilesAcademic}</span>
                                {error && <p className='error-message'>{error}</p>}
                                {files.length > 0 && <h3 className='academicWork'>Academic Document</h3>}
                                <FileList files={files} removeFile={removeFile} setter={setFiles} base64Setter={setBase64FilesAcademic} base64Files={base64FilesAcademic} />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <h3 className="title fz18 font-bold mb10">Work History</h3>
                                <div className='FileCont' {...getRootPropsWrk()}>
                                    <input {...getInputPropsWrk()} />
                                    <PiExport className='FilePdfIcon' />
                                    <p className='FilePdfIconPBold'>Click or drag file to this area to upload</p>
                                    <p className='FilePdfIconP'>Upload document as a file in pdf</p>
                                </div>
                                <span style={{color:'red'}}>{detailErr.base64FilesWork}</span>
                                {error && <p className='error-message'>{error}</p>}
                                {workFiles.length > 0 && <h3 className='academicWork'>Work Document</h3>}
                                <FileList files={workFiles} removeFile={removeFile} setter={setWorkFiles} base64Setter={setBase64FilesWork} base64Files={base64FilesWork} />
                            </div>
                        </div>
                        <div className="form-group row mt20 border-top pt-20">
                            <div className="col-lg-12 col-md-12 align-right">
                                <button className="theme-btn cancel-btn font-bold fz16 mr20" type="button" onClick={()=>{cancelBtn('/home')}}>Cancel</button>
                                <button className="theme-btn submit-btn font-bold fz16" type="submit">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} id='jobSpecifies'>
                <div className="checkout-form">
                    <form onSubmit={jobSpecifies}>
                        <div className="form-group row">
                            <div className="col-lg-6 col-md-12">
                                <label>Religion:</label>
                                {/* <Dropdown
                                    options={religionSelect}
                                    value={religion}
                                    placeholder="Select Religion"
                                /> */}
                                <Select
                                    options={religionSelect}
                                    value={religion}
                                    placeholder="Select Religion"
                                />
                                <label className='mt20'>Books:</label>
                                {/* <Dropdown
                                    options={booksSelect}
                                    value={books}
                                    placeholder="Select Books"
                                /> */}
                                <Select
                                    options={booksSelect}
                                    isMulti
                                    value={books}
                                    placeholder="Select Books"
                                />
                                <label className='mt20'>Denomination:</label>
                                {/* <Dropdown
                                    options={denominationSelect}
                                    value={denomination}
                                    placeholder="Select Denomination"
                                /> */}
                                <Select
                                options={denominationSelect}
                                value={denomination}
                                placeholder="Select Denomination"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <h3 className="title fz18 font-bold mb10">Add Cover Letter</h3>
                                <div className='FileCont' {...getRootPropsLetter()}>
                                    <input {...getInputPropsLetter()} />
                                    <PiExport className='FilePdfIcon' />
                                    <p className='FilePdfIconPBold'>Click or drag file to this area to upload</p>
                                    <p className='FilePdfIconP'>Upload document as a file in pdf</p>
                                </div>
                                <span style={{color:'red', textAlign:'end'}}>{jobSave}</span>
                                {error && <p className='error-message'>{error}</p>}
                                {letterFiles.length > 0 && <h3 className='academicWork'>Cover Letter Document</h3>}
                                <FileList files={letterFiles} removeFile={removeFile} setter={setLetterFiles} base64Setter={setBase64FilesLetter} base64Files={base64FilesLetter} />
                            </div>
                        </div>
                        <div className="form-group row mt20 border-top pt-20">
                            <div className="col-lg-12 col-md-12 align-right">
                                <button className="theme-btn cancel-btn font-bold fz16 mr20" type="button" onClick={()=>{setValue(0)}}>Cancel</button>
                                <button href='home' className="theme-btn submit-btn font-bold fz16" type="submit">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </CustomTabPanel>
        </Box>
    );
}





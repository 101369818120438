import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
      <footer className="main-footer alternate">
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="outer-box">
              <div className="copyright-text">
                <a href='/'><img className="logo-width mr30" src="/assets/images/logo/ev-zone-logo.svg" draggable='false' alt="" /></a>
                <a href="https://evzone.app/" target="_blank" className='mr30'> Privacy Policy<img className="ml10" src="/assets/images/icons/arrow-export.png" draggable='false' alt="" /></a>
                <a href="https://evzone.app/" target="_blank">Terms<img className="ml10" src="/assets/images/icons/arrow-export.png" draggable='false' alt="" /></a></div>
              <div className="social-links"> <a href="https://evzone.app/" target="_blank"><img className="mr10" src="/assets/images/icons/question-circle.png" draggable='false' alt="" />Help<img className="ml10" src="/assets/images/icons/arrow-export.png" draggable='false' alt="" /></a> </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
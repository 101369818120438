import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
const Locations = () => {
    return (
        <>
            <section className="page-title style-two mt70 bg-fafbfc">
                <div className="auto-container">
                    <div className="sec-title align-center mb-0"><h3 className="fz32">Our Locations</h3><div className="text">Explore job opportunities across our multiple locations. Find the  perfect position near you and start your career journey with us.</div></div>
                </div>
            </section>
            <section className="contact-section pt-0">
                <div className="auto-container">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='map-tab'>
                                <ul>
                                    <li><a href='#'>Africa</a></li>
                                    <li><a href='#'>Latin America</a></li>
                                    <li><a href='#'>Europe</a></li>
                                    <li><a href='#'>Middle East</a></li>
                                    <li><a href='#'>Asia / Oceania</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12'>
                            <div className="form-group mt20">
                                <input type="text" name="search" className="register-form" placeholder="Search" />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-9 col-sm-12'><img src='/assets/images/locations.png' draggable='false'/></div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Locations
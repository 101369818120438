import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Configuration, getDetails, jobListTeam } from '../Api/Url';
import { Link, useLocation, useNavigate, useHistory } from 'react-router-dom';

const Teams = () => {
    const config = Configuration();
    const [teamName, setTeamName] = useState([]);
    const [loading, setLoading] = useState(true); 
    const allowUserNav = useNavigate();
    const applyJobNav = useNavigate();
    
    useEffect(() => {
        axios.get(jobListTeam(), config)
            .then(res => {
                setTeamName(res.data.data);
                setLoading(false); 
            })
            .catch(err => {
                console.log(err);
                setLoading(false); 
            });
    }, []);

    // const handleAllowUser = (team_id) => {
    //     axios.get(getDetails(), config)
    //         .then(res => {
    //             if (res.data.data.user_detail == null || res.data.data.get_user_job_specify_detail == null) {
    //                 allowUserNav('/userapplication');
    //             } else {
    //                 applyJobNav(`/jobs?team_id=${team_id}`);
    //             }
    //         })
    //         .catch(err => console.error('Error fetching details:', err));
    // }
    
    const [loginId, setLoginId] = useState('');
    
    useEffect(() => {
      const globalValue = window.globalValue;
      setLoginId(globalValue);
      console.log(globalValue, '--------');
    }, [window.globalValue]);
    console.log(loginId, '********');

    const handleAllowUser=(team_id)=>{
        if(loginId){
            applyJobNav(`/u/${loginId}/jobs?team_id=${team_id}`);
        }
        else{
            applyJobNav(`/jobs?team_id=${team_id}`);
        }
    }

    const expectedLength = teamName.length > 0 ? teamName.length : 9;

    return (
        <>
            <div className="sidebar-page-container mt20">
                <div className="auto-container">
                    <div className="align-items-center justify-content-between wow fadeInUp">
                        <div className="sec-title align-center">
                            <h3 className='fz32'>Teams</h3>
                            <div className="text">Explore a wide range of job categories tailored to your skills and interests. Find the perfect opportunity that matches your career goals.</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="blog-grid">
                                <div className="row">
                                    {loading ? (
                                        // Display loading state
                                        Array.from({  length: expectedLength }).map((_, index) => (
                                            <div key={index} className="animated-bg news-block col-lg-4 col-md-6 col-sm-12">
                                                <div className="inner-boxLoading animated-bg">
                                                    <div className="image-box">
                                                        <figure className="image loading-image"></figure>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                                        <h3 className='loading-content'></h3>
                                                        <a className='loading-content'></a>
                                                    </div>
                                                    {/* <div className="lower-content space-between display-flex">
                                                        <div className="loading-text" style={{display:'flex', justifyContent:'space-between', width:}}>
                                                            <h3 className='mb-0'>View Jobs</h3>
                                                            <a>View Jobs</a>
                                                            <div className="animated-bg" style={{ height: '20px', width: '60%'}}></div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        // Display team data
                                        teamName && teamName.map(value => (
                                            <div key={value.id} className="news-block col-lg-4 col-md-6 col-sm-12">
                                                <div className="inner-box">
                                                    <div className="image-box">
                                                        <figure className="image">
                                                            <img src="/assets/images/teams/team01.png" alt="" draggable='false' />
                                                        </figure>
                                                    </div>
                                                    <div className="lower-content space-between display-flex">
                                                        <h3 className='mb-0'>{value.name}</h3>
                                                        <a onClick={() => { handleAllowUser(value.id) }}>View Jobs</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Teams;

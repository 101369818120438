import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Testimonials from '../components/testimonial';
const PerksBenefits = () => {
    return (
        <>
            <section className="testimonial-section-three mt-60">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <h3>Perks & Benefits</h3>
                        <div className="text">Experience the peace of mind that comes with comprehensive health  coverage, ensuring you and your family are well-protected. Our  retirement plans are designed to help you secure your financial future,  providing stability and confidence for the years ahead. Additionally,  take advantage of our generous paid time off policies, allowing you the  flexibility to recharge, spend time with loved ones, and pursue your  personal interests.</div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <figure className="image"><img src="/assets/images/perks-benefits/perks01.png" alt="" draggable='false'/></figure>
                        </div>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <h4 className="perks-title">Unmatched <br />Benefits</h4>
                            <div className="perks-text">Experience the peace of mind that comes with comprehensive health  coverage, ensuring you and your family are well-protected. Our  retirement plans are designed to help you secure your financial future,  providing stability and confidence for the years ahead. Additionally,  take advantage of our generous paid time off policies, allowing you the  flexibility to recharge, spend time with loved ones, and pursue your  personal interests.</div>
                        </div>
                    </div>
                    <div className='row mt30'>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <h4 className="perks-title">Investing in<br />Your Future</h4>
                            <div className="perks-text">Take advantage of our continuous learning opportunities to stay updated  with the latest industry trends and enhance your skills. Our career  advancement programs provide clear pathways for growth, helping you  achieve your professional goals. Additionally, we offer comprehensive  professional development support, including workshops, seminars, and  mentorship programs, to foster your personal and professional growth.</div>
                        </div>
                        <div className='col-lg-6 col-lg-6 col-sm-12 text-align-right'>
                            <figure className="image"><img src="/assets/images/perks-benefits/investing.png" alt="" draggable='false'/></figure>
                        </div>
                    </div>
                    <div className='row mt30'>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <figure className="image"><img src="/assets/images/perks-benefits/work-life.png" alt="" draggable='false'/></figure>
                        </div>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <h4 className="perks-title">Work-Life <br />Harmony</h4>
                            <div className="perks-text">Enjoy flexible working hours that let you tailor your schedule to fit  your life and maximize productivity. Embrace remote work options,  offering the freedom to work from any location that suits you best. Our  work-life balance initiatives support your overall well-being, helping  you effectively manage both personal and professional responsibilities. These benefits are designed to create a harmonious and fulfilling work environment.</div>
                        </div>
                    </div>
                    <div className='row mt30'>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <h4 className="perks-title">Health and<br />Wellness</h4>
                            <div className="perks-text">Access a comprehensive array of wellness programs designed to support  both your physical and mental health. Benefit from a variety of mental  health resources, including counseling services and stress management  workshops. Stay active and fit with our on-site fitness facilities,  equipped with state-of-the-art equipment and offering diverse fitness  classes. These combined resources ensure you maintain a healthy,  balanced, and happy lifestyle.</div>
                        </div>
                        <div className='col-lg-6 col-lg-6 col-sm-12 text-align-right'>
                            <figure className="image"><img src="/assets/images/perks-benefits/health.png" alt="" draggable='false'/></figure>
                        </div>
                    </div>
                    <div className='row mt30'>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <figure className="image"><img src="/assets/images/perks-benefits/innovative.png" alt="" draggable='false'/></figure>
                        </div>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <h4 className="perks-title">Innovative<br />Culture</h4>
                            <div className="perks-text">Collaborate with talented and innovative teams, bringing together  diverse skills and perspectives to achieve common goals. Engage in  cutting-edge projects that push the boundaries of technology and  creativity, allowing you to stay at the forefront of your field. Drive  meaningful impact through your work, contributing to projects that make a  difference in people's lives and industries. Together, we create a  dynamic and fulfilling work environment.</div>
                        </div>
                    </div>
                    <div className='row mt30'>
                        <div className='col-lg-6 col-lg-6 col-sm-12'>
                            <h4 className="perks-title">Grow<br />With us</h4>
                            <div className="perks-text">Take advantage of continuous learning opportunities that keep you  up-to-date with the latest industry trends and skills. Benefit from  career advancement programs designed to help you achieve your  professional goals and grow within the company. Participate in  mentorship opportunities that provide guidance, support, and insights  from experienced professionals. Together, these resources create a  nurturing environment.</div>
                        </div>
                        <div className='col-lg-6 col-lg-6 col-sm-12 text-align-right'>
                            <figure className="image"><img src="/assets/images/perks-benefits/grow.png" alt="" draggable='false'/></figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className='testimonial-section-three'>
                <div className='auto-container'>
                    <div className="testimonial-block-four">
                        <Testimonials />
                    </div>
                </div>
            </section>
        </>
    );
}
export default PerksBenefits;
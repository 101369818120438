import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { LuEye } from "react-icons/lu"; 
import { LuEyeOff } from "react-icons/lu";

const Register = () => {

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    return (
        <>
            <section className='tnc-section bg-fafbfc'>
                <div className="auto-container">
                    <div className="login-form default-form">
                        <div className="form-inner form-card">
                            <h3>Register</h3>
                            <div className="bottom-box">
                                <div className="btn-box row">
                                    <div className="col-lg-6 col-md-12"> <a href="#" className="theme-btn social-btn-two google-btn"><img className='mr20' src='assets/images/icons/g-mail.svg' draggable='false'/> Sign up with Google</a> </div>
                                    <div className="col-lg-6 col-md-12"> <a href="#" className="theme-btn social-btn-two facebook-btn"><img className='mr20' src='assets/images/icons/apple.svg' draggable='false'/> Sign up with Apple</a> </div>
                                </div>
                                <div className="divider"><span>or</span></div>
                            </div>
                            <div className="form-group">
                                <input className='register-form' type="name" name="name" placeholder="Name" required />
                            </div>
                            <div className="form-group">
                                <input className='register-form' type="country" name="country" placeholder="Country" required />
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6 col-md-12">
                                    <input className='register-form' type="" name="" placeholder="Email Address" required /></div>
                                <div className="col-lg-6 col-md-12">
                                    <input className='register-form' type="" name="" placeholder="Mobile Number" required /></div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6 col-md-12 passwordEyeCont">
                                    <input className='register-form' type={passwordVisible ? "text" : "password"} name="" placeholder="Password" required />
                                    <span className="toggle-icon" onClick={togglePasswordVisibility}>
                                        {passwordVisible ? <LuEye /> : <LuEyeOff />}
                                    </span>
                                </div>
                                <div className="col-lg-6 col-md-12 passwordEyeCont">
                                    <input className='register-form' type={confirmPasswordVisible ? "text" : "password"} name="" placeholder="Confirm Password" required />
                                    <span className="toggle-icon" onClick={toggleConfirmPasswordVisibility}>
                                        {confirmPasswordVisible ? <LuEye /> : <LuEyeOff />}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group">
                                {/* <input className='register-form' type="text" name="ReferralCode" value="" placeholder="Referral Code" /> */}
                                <input type='text' placeholder='Referral Code' className='register-form'/>
                            </div>
                            <div className="form-group">
                                <ul class="checkboxes square">
                                    <li>
                                        <input id="check-a" type="checkbox" name="check" />
                                        <label for="check-a">You must agree our terms and conditions</label>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-group">
                                {/* <button className="theme-btn btn-style-one font-bold fz16" type="submit" name="Register" href='userapplication'>Submit</button> */}
                                <a href='userapplication' className='theme-btn btn-style-one font-bold fz16'>Submit</a>
                            </div>
                            <div className="form-group">
                                <p className='text-center'>Already have an account? <a className='color-green' href='#'>Log in</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Register
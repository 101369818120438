import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Homepage from '../pages/home';
import Jobs from '../pages/jobs';
import Teams from '../pages/teams';
import PerksBenefits from '../pages/perksbenefits';
import JobsFilter from '../pages/jobsfilter';
import JobDetails from '../pages/jobdetails';
import Register from '../pages/register';
import UserApplication from '../pages/userapplication';
import Locations from '../pages/locations';

const BaseLayouts = () => {

    const [authUser, setAuthUser] = useState(null);
    const [jobId, setJobId] = useState('');

useEffect(() => {
    const path = window.location.pathname;
    const match = path.match(/\/u\/(\d+)/);
    const authUserId = match ? match[1] : null;
    setAuthUser(authUserId);
    console.log(authUserId);
    console.log('testing');
}, []);

// get jobId
useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    setJobId(urlParams.get('job_id'));
})
const [loginId, setLoginId] = useState('');

console.log(window.globalValue);

useEffect(() => {
  const globalValue = window.globalValue;
  setLoginId(globalValue);
  console.log(globalValue, '--------');
}, [window.globalValue]);
console.log(loginId, '********');

    return (
        <>
            <React.Fragment>
                <BrowserRouter>
                    <Header />
                    
                    {authUser ? (
                        <Routes>
                            {console.log('1st')}
                            {console.log(authUser)}
                            <Route path={`/u/${authUser}`} element={<Homepage />} />
                            <Route path={`/u/${authUser}/home`} element={<Homepage />} />
                            <Route path={`/u/${authUser}/jobs`} element={<Jobs />} />
                            <Route path={`/u/${authUser}/teams`} element={<Teams />} />
                            <Route path={`/u/${authUser}/PerksBenefits`} element={<PerksBenefits />} />
                            <Route path={`/u/${authUser}/jobsfilter`} element={<JobsFilter />} />
                            <Route path={`/u/${authUser}/jobdetails`} element={<JobDetails />} />
                            {console.log(`/u/${authUser}/jobdetails?job_id=${jobId}`)}
                            <Route path={`/u/${authUser}/register`} element={<Register />} />
                            <Route path={`/u/${authUser}/userapplication`} element={<UserApplication />} />
                            <Route path={`/u/${authUser}/locations`} element={<Locations />} />
                            {console.log('1st end')}
                        </Routes>
                    ) : (
                        <Routes>
                            {console.log('2st')}
                            <Route path="/" element={<Homepage />} />
                            <Route path="/home" element={<Homepage />} />
                            <Route path="/jobs" element={<Jobs />} />
                            <Route path="/teams" element={<Teams />} />
                            <Route path="/PerksBenefits" element={<PerksBenefits />} />
                            <Route path="/jobsfilter" element={<JobsFilter />} />
                            <Route path="/jobdetails" element={<JobDetails />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/userapplication" element={<UserApplication />} />
                            <Route path="/locations" element={<Locations />} />
                        </Routes>
                    )}

                    <Footer />
                </BrowserRouter>
            </React.Fragment>
        </>
    );
};

export default BaseLayouts;

import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default class Testimonials extends Component {
    render() {
        return (
            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={6100}
            >
                <div className="display-flex">
                    <div><img src="assets/images/perks-benefits/person.png" draggable='false'/>
                        <h3 className="myCarousel-title">Jack Williams</h3>
                        <h4 className="myCarousel-sm-title">Teacher</h4>
                    </div>
                    <div className="myCarousel">
                        <p>Working with EVzone has been an exceptional  experience. Their team is highly skilled, responsive, and  dedicated to delivering top-quality work. They took the time to  understand my project requirements and provided creative solutions that  truly brought my vision to life.</p>
                    </div>
                </div>
                <div className="display-flex">
                    <div><img src="assets/images/perks-benefits/person.png" draggable='false'/>
                        <h3 className="myCarousel-title">Jack Williams</h3>
                        <h4 className="myCarousel-sm-title">Teacher</h4>
                    </div>
                    <div className="myCarousel">
                        <p>Working with EVzone has been an exceptional  experience. Their team is highly skilled, responsive, and  dedicated to delivering top-quality work. They took the time to  understand my project requirements and provided creative solutions that  truly brought my vision to life.</p>
                    </div>
                </div>
                <div className="display-flex">
                    <div><img src="assets/images/perks-benefits/person.png" draggable='false'/>
                        <h3 className="myCarousel-title">Jack Williams</h3>
                        <h4 className="myCarousel-sm-title">Teacher</h4>
                    </div>
                    <div className="myCarousel">
                        <p>Working with EVzone has been an exceptional  experience. Their team is highly skilled, responsive, and  dedicated to delivering top-quality work. They took the time to  understand my project requirements and provided creative solutions that  truly brought my vision to life.</p>
                    </div>
                </div>
            </Carousel>
        );
    }
}
